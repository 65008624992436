import Vue from "vue";
import App from "./App.vue";
import router from './router';
import axios from 'axios';
import Vuex from 'vuex'
import vuetify from "./plugins/vuetify";
import createPersistedState from "vuex-persistedstate";

axios.defaults.withCredentials = true;

Vue.use(Vuex)

Vue.prototype.$http = axios;
//Vue.config.productionTip = false;


const store = new Vuex.Store({
  plugins: [createPersistedState()],
  getters : {
    isLoggedIn: state => !!state.user.authToken,
    authStatus: state => state.user.loggedInStatus,
  },
  state: {
    notification: {
      action:false,
      time:0,
      text:'',
      color:'',
    },
    user: {
      userName: '',
      loggedInStatus: false,
      authToken: ''
    }
  },
  mutations: {
    addWebToken: function (state, data) {
      console.log('si es admin', data);
      state.user.authToken = data.token;
      state.user.userName = data.username;
      state.user.name = data.name;
      state.user.menu = data.menu;
      state.user.customer = data.customer;
      state.user.loggedInStatus = true;
      console.log('si es admin', state.user);
    },
    removeWebToken: function (state) {
      state.user.authToken = '';
      state.user.userName = '';
      state.user.name = '';
      state.user.menu = '';
      state.user.loggedInStatus = false;
    },
    actionNotification: function (state, options) {
      state.notification.action = options.action;
      state.notification.time = options.time;
      state.notification.text = options.text;
      state.notification.color = options.color;
    }
  },
  actions: {
    notificationAction : (context, options) => {
      context.commit('actionNotification', options);
    },
    login: function (context, userInput) {
      //return Vue.prototype.$http.post(process.env.VUE_APP_LOGIN_URI, {username: userInput.username, password: userInput.password})
      //return Vue.prototype.$http.post(`${process.env.VUE_APP_LOGIN_URI}`'https://sistema.colegio5159.com.ar/api/sanctum/token', {email: userInput.username, password: userInput.password, device_name:'pc'})
      return Vue.prototype.$http.post(`${process.env.VUE_APP_LOGIN_URI}`, {email: userInput.username, password: userInput.password, device_name:'pc'})
        .then(function(response) {
          console.log('response login', response.data);
          if(response.data.error == undefined){
            response.data.username = userInput.username;
            context.commit('addWebToken', response.data); // pass the webtoken as payload to the mutation
            router.push({
              path: '/dashboard',
            });
          }
          console.log('token',response.data);
          return response.data;
        })
        .catch( err => console.log( err))
    },
    logout: function (context) {
      //your logout functionality
      context.commit('removeWebToken');
      console.log('Saliendo');
      router.push({
          path: '/login',
      });
    }
  }
})

axios.interceptors.request.use(function(config) {
  //console.log(config);
  const token = store.state.user.authToken;
    if(token) {
        config.headers.Authorization =  `Bearer ${token}`;
   }
    return config;
}, function(err) {
    return Promise.reject(err);
});

router.beforeEach((to, from, next) => {
  console.log('ingresando x main.js----------------------------');
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      console.log('Esta logeado...');
      next()
      return
    }
    console.log('enviando a login');
    next('/login')
  } else {
    console.log('no paso nada');
    next()
  }
})

new Vue({
  vuetify,
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')