<template>
  <v-app>
   <v-navigation-drawer
      width="350"
      v-model="drawer"
      app
      style='z-index:20001;'
      v-if="currentRouteName != 'Login'"
    >
      <v-list
        nav
        dense
      >
      <!-- active-class="deep-blue--text text--accent-4" -->
        <!-- <v-list-item-group
          v-model="group"
        > -->
          <v-list-item
            exact
            to="/dashboard"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>

          <div v-for="(item, index) in getDefaultMenu()" :key="index" >

            <!-- Si el Menu no tiene submenu -->

              <v-list-item
                v-if="item.permissions"
                exact
                :to="item.path">

                <v-list-item-icon>
                  <v-icon> {{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>

             <!-- Si el Menu tiene bubmenu -->
              <v-list-group
                v-else
                :key="item.name"
                no-action
                :prepend-icon="item.icon"
              >
                <template v-slot:activator>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </template>

                <v-list-item
                    v-for="child in item.submenu"
                    :key="child.name"
                    exact
                    :to="child.path"
                >
                  <v-list-item-icon >
                    <v-icon x-small >{{child.icon}}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title >{{ child.name }}</v-list-item-title>

                </v-list-item>
              </v-list-group>

          </div>

          <v-list-item
            exact to="/logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="secundary">
      <v-app-bar-nav-icon v-if="currentRouteName != 'Login'" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="logo"
          transition="scale-transition"
          width="40"
        />

        Web Admin Colegio 5159
      </div>

      <v-spacer></v-spacer>
      <div
          class="mr-4 ma-2"
        >
          <v-icon medium left v-if="$store.state.user.name">
            mdi-account
          </v-icon>
          <span class="username">{{$store.state.user.name}}</span>
      </div>

      <v-chip
          v-if="$store.state.user.name"
          class="ma-2"
          dark
          @click="logout"
        >
        <v-icon left>
            mdi-logout
          </v-icon>
          Cerrar Sesión
      </v-chip>

    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

     <v-snackbar
      v-model="$store.state.notification.action"
      :timeout="$store.state.notification.time"
      centered
    >
      {{ $store.state.notification.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$store.state.notification.color"
          text
          v-bind="attrs"
          @click="closeSnackbar"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>


export default {
  name: "App",
  components: {
  },
  data: () => ({
    menu: [],
    drawer: false,
    group: null,
    logo: require('@/assets/logo.png'),
    snackbar: false,
    text: 'hola mundo'

  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted(){
  },
  created(){
    this.closeSnackbar();
  },

  methods:{
    closeSnackbar(){
        const options = {
                    action: false,
                    text:"",
                    time: 0,
                    color:'',
                }
      this.$store.dispatch("notificationAction", options);
    },
    logout(){
      this.$router.push('/logout')
    },
    getDefaultMenu(){
      //return this.$store.state.user.roles.find(el => el.default === true).menus.filter( m => m.code != 'HOM');
      return this.$store.state.user.menu;
    },
  }
};
</script>

<style scoped>

.username {
  font-size: 13px;
}
.v-list-item--active {
  background-color: rgb(66, 139, 139);
}

.v-list-item-title--active {
  background-color: rgb(58, 141, 83);
}
.option_active{
  background-color: rgb(29, 31, 32);
}

</style>
