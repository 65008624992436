import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
    {
      path:"/",
      name:"home",
      meta: {
        requiresAuth: true,
      },
      component: () => import('../views/Dashboard.vue')
    },
   {
    path:"/login",
    name:"Login",
    component: () => import('../views/Login.vue'),
  },
  {
    path:"/logout",
    name:"logout",
    component: () => import('../views/Logout.vue')
  },
  {
    path:"/dashboard",
    name:"dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path:"/students",
    name:"studnets",
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Dashboard.vue')
  },
  // {
  //   path:"/inscriptions",
  //   name:"inscriptions",
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   component: () => import('../views/Dashboard.vue')
  // },
  {
    path:"/calendary",
    name:"calendary",
    component: () => import('../views/Calendary.vue')
  },
  {
    path:"/my_courses",
    name:"my_courses",
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/preceptors/MyCourses.vue')
  },
  {
    path:"/Inscriptions",
    name:"inscriptions",
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/preceptors/Inscriptions.vue')
  },
  {
    path:"/califications",
    name:"califications",
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/preceptors/Califications.vue')
  },
  {
    path:"/get_grades",
    name:"get_grades",
    // meta: {
    //   requiresAuth: true,
    // },
    component: () => import('../views/graphics/Subjects.vue')
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// axios.interceptors.request.use(function(config) {
//   //console.log(config);
//   const token = store.state.user.authToken;
//     if(token) {
//         config.headers.Authorization =  `Bearer ${token}`;
//    }
//     return config;
// }, function(err) {
//     return Promise.reject(err);
// });

// router.beforeEach((to, from, next) => {
//   //const statusLogin = router.app.$store.state.user.loggedInStatus;
//   console.log('session to.....', to, 'session from .....',from,'router.app.$store......',  router.app);
//   next();
//   console.log('from',from);
//   // if(to.path === '/login' && statusLogin){
//   //   next({ path: '/' });
//   // } else {
//   //   next();
//   // }
// })


export default router
